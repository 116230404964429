<template>
  <div class="col-6">
    <div id="competition-item">
      <img
        v-bind:src="'data:image/jpeg;base64,'+this.listing.featuredImage"
        alt=""
      />
    </div>

    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="70"
        viewBox="0 0 702 91"
      >
        <defs>
          <filter
            id="Rectangle_186"
            x="0"
            y="0"
            width="702"
            height="91"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood flood-opacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_186)">
          <rect
            id="Rectangle_186-2"
            data-name="Rectangle 186"
            width="684"
            height="73"
            rx="36.5"
            transform="translate(9 6)"
            fill="#4fd1c1"
          />
        </g>
        <circle
          id="Ellipse_538"
          data-name="Ellipse 538"
          cx="29.5"
          cy="29.5"
          r="29.5"
          transform="translate(17 13)"
          fill="#fff"
        />
        <path
          id="Icon_awesome-crown"
          data-name="Icon awesome-crown"
          d="M25.575,21.7H5.425a.777.777,0,0,0-.775.775v1.55a.777.777,0,0,0,.775.775h20.15a.777.777,0,0,0,.775-.775v-1.55A.777.777,0,0,0,25.575,21.7Zm3.1-15.5A2.326,2.326,0,0,0,26.35,8.525a2.278,2.278,0,0,0,.213.959l-3.507,2.1a1.548,1.548,0,0,1-2.141-.562L16.968,4.117a2.325,2.325,0,1,0-2.935,0l-3.948,6.907a1.549,1.549,0,0,1-2.141.562l-3.5-2.1A2.324,2.324,0,1,0,2.325,10.85a2.374,2.374,0,0,0,.373-.039L6.2,20.15H24.8l3.5-9.339a2.374,2.374,0,0,0,.373.039,2.325,2.325,0,0,0,0-4.65Z"
          transform="translate(31 30)"
          fill="#4fd1c1"
        />
      </svg>

      <input v-if="!this.listing.winner"
        type="text"
        id="winner_ticket"
        name="winner_ticket"
        placeholder="Enter winning ticket number"
        autocomplete="off"
        v-model="winningNumber"
      />
      <button v-on:click="setWinner">Submit winning number</button>
    </div>
  </div>
</template>

<script>
import api from '../../scripts/api'

export default {
    props: {
        listing:Object
    },
    data() {
        return {
            winningNumber: undefined
        }
    },
    methods: {
        setWinner: async function() {
            if(!this.winningNumber && this.winningNumber !== 0) this.$toast.error('Please enter a winning number.');

            var result = await api.admin.setCompetitionWinner(this.listing.id, parseInt(this.winningNumber));
            if(result.status !== 200) {
                if(result.status == 409) this.$toast.error('Nobody bought this ticket, draw again.');
                return this.$toast.error('Unknown error occured, try again.');
            }

            this.$toast.success('Winner set!');
        }
    }
}
</script>