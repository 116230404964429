<template>
  <div>

    <section id="dashboard" class="mt-4">
      <div class="container">
        <div class="row">
          <SideNav v-bind:selected='1' />
          
          <div class="col-12">

            <div class="row" v-if="listings && listings.length > 0">
              <EndedListing v-for="listing in listings" v-bind:key="listing.id" v-bind:listing="listing" />
            </div>


            <div v-else class="text-center" style="padding-top:30px;">
              <h3>No competitions running.</h3>
              <router-link v-bind:to="'/admin/add-listing'"><button type="button" class="btn btn-custom mt-3 w-25">Add Listing</button></router-link>
            </div>




          </div>
        </div>
      </div>
    </section>
  </div>

  <Footer />
</template>

<script>
import Api from '../../scripts/api'

import SideNav from '../../components/admin/SideNavigator'
import Footer from '../../components/global/Footer'

import EndedListing from '../../components/admin/EndedListing'

export default {
  components: {
    SideNav,
    Footer,
    EndedListing
  },
  props: {
    account: Object
  },
  data() {
    return {
      listings: []
    }
  },
  async created() {
      var isAdmin = await Api.admin.isAdmin();
      if(isAdmin.status != 200) {
        this.$router.push('/');
      }

      var result = await Api.competition.listExpired();
      if(result.status == 200) {
        this.listings = await result.json();
      }
    }
};
</script>

